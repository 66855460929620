import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    image: file(relativePath: { eq: "Banners/NewHalva/iphone.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export default function IPhoneImg(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)

  return <GatsbyImage {...props} image={getImage(data.image)!} />
}
