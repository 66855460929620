// extracted by mini-css-extract-plugin
export var btn = "whatAreTheBenefits-module--btn--qXfIa";
export var descr = "whatAreTheBenefits-module--descr--EZHvE";
export var halva_bg = "whatAreTheBenefits-module--halva_bg--KoWlx";
export var img = "whatAreTheBenefits-module--img--Nqx1a";
export var img_container = "whatAreTheBenefits-module--img_container--OQGeX";
export var offer = "whatAreTheBenefits-module--offer--c5ix0";
export var offer_container = "whatAreTheBenefits-module--offer_container--9LP5U";
export var order_container = "whatAreTheBenefits-module--order_container--MDs2i";
export var price = "whatAreTheBenefits-module--price--e-Hyw";
export var section = "whatAreTheBenefits-module--section--FJZgN";
export var sectionAnotherIndent = "whatAreTheBenefits-module--sectionAnotherIndent--OfPSn";
export var star = "whatAreTheBenefits-module--star--IIVfA";
export var subtext = "whatAreTheBenefits-module--subtext--eKq1j";
export var subtitle = "whatAreTheBenefits-module--subtitle--UTeCJ";
export var text = "whatAreTheBenefits-module--text--E9uuh";
export var title = "whatAreTheBenefits-module--title--qOASZ";
export var title_text = "whatAreTheBenefits-module--title_text--ocxoH";
export var wrapper = "whatAreTheBenefits-module--wrapper--tFBD4";