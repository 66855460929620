import React, { ReactNode } from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import * as styles from "./whatAreTheBenefits.module.scss"

import StarImg from "./img/StarImg"
import HalvaBg from "./img/HalvaBg"
import IPhoneImg from "./img/IPhoneImg"
import { handleClickBanner } from "../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить карту"

type WhatAreTheBenefitsProps = {
  title?: ReactNode
  price?: ReactNode
  text?: ReactNode
  anotherIndentTop?: boolean
  orderNum?: string
}

export const WhatAreTheBenefits = ({
  title = "10 месяцев рассрочки",
  price = "6 480 ₽ / мес.",
  text,
  anotherIndentTop = false,
  orderNum,
}: WhatAreTheBenefitsProps) => (
  <div
    className={anotherIndentTop ? styles.sectionAnotherIndent : styles.section}
    data-exclude={orderNum}
  >
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.offer_container}>
          <h4 className={styles.title}>{title}</h4>
          {text ? (
            <p className={styles.descr}>{text}</p>
          ) : (
            <>
              <p className={styles.text}>Три простых шага:</p>
              <ul className={styles.offer}>
                <li>Оформите карту «Халва»</li>
                <li>
                  С 05.12.2022 по 08.01.2023 зарегистрируйтесь в&nbsp;акции «В Новый год с Халвой»
                </li>
                <li>
                  Получите 10 месяцев рассрочки на&nbsp;любые товары у партнеров в&nbsp;период акции
                </li>
              </ul>
            </>
          )}
        </div>
        <div className={styles.order_container}>
          <h4 className={styles.subtitle}>
            iPhone 14 Pro 256GB <br /> Deep Purple
          </h4>
          <h3 className={styles.price}>{price}</h3>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </div>
        <div className={styles.img_container}>
          <IPhoneImg alt="iphone" className={styles.img} />
        </div>
        <StarImg className={styles.star} />
        <HalvaBg className={styles.halva_bg} objectPosition="top left" alt="" />
      </div>
    </Container>
  </div>
)
