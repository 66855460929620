import React from "react"

export default function StarImg({ className }: { className?: string }) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.0005 7.67002C19.9373 7.48711 19.8224 7.32645 19.6698 7.20753C19.5171 7.0886 19.3333 7.01652 19.1405 7.00002L13.4505 6.17002L10.9005 1.00002C10.8186 0.830952 10.6907 0.688365 10.5316 0.588597C10.3724 0.488828 10.1883 0.435913 10.0005 0.435913C9.81261 0.435913 9.62855 0.488828 9.46938 0.588597C9.3102 0.688365 9.18235 0.830952 9.10047 1.00002L6.55047 6.16002L0.860466 7.00002C0.675388 7.02633 0.50139 7.10399 0.358216 7.22418C0.215042 7.34438 0.108426 7.5023 0.0504657 7.68002C-0.00258931 7.8537 -0.00735037 8.03854 0.0366935 8.21472C0.0807373 8.39089 0.171923 8.55175 0.300466 8.68002L4.43047 12.68L3.43047 18.36C3.39477 18.5475 3.41346 18.7413 3.48434 18.9185C3.55522 19.0957 3.67532 19.2489 3.83047 19.36C3.98168 19.4681 4.16004 19.5319 4.34551 19.5443C4.53099 19.5567 4.71624 19.5171 4.88047 19.43L10.0005 16.76L15.1005 19.44C15.2408 19.5192 15.3993 19.5606 15.5605 19.56C15.7723 19.5608 15.9789 19.4942 16.1505 19.37C16.3056 19.2589 16.4257 19.1057 16.4966 18.9285C16.5675 18.7513 16.5862 18.5575 16.5505 18.37L15.5505 12.69L19.6805 8.69002C19.8248 8.5677 19.9316 8.40693 19.9882 8.22637C20.0448 8.04582 20.0491 7.8529 20.0005 7.67002ZM13.8505 11.67C13.7332 11.7835 13.6454 11.9239 13.5949 12.079C13.5444 12.2341 13.5325 12.3993 13.5605 12.56L14.2805 16.75L10.5205 14.75C10.3758 14.673 10.2144 14.6327 10.0505 14.6327C9.88655 14.6327 9.72515 14.673 9.58047 14.75L5.82047 16.75L6.54047 12.56C6.5684 12.3993 6.55658 12.2341 6.50603 12.079C6.45548 11.9239 6.36774 11.7835 6.25047 11.67L3.25047 8.67002L7.46047 8.06002C7.62246 8.03749 7.77646 7.97556 7.90896 7.87967C8.04147 7.78379 8.14842 7.65686 8.22047 7.51002L10.0005 3.70002L11.8805 7.52002C11.9525 7.66686 12.0595 7.79379 12.192 7.88968C12.3245 7.98556 12.4785 8.04749 12.6405 8.07002L16.8505 8.68002L13.8505 11.67Z"
        fill="#292929"
      />
    </svg>
  )
}
